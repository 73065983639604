.subscription-details-container {
  text-align: left;
  border-radius: 10px;
  width: 100%;
  font-family: 'Arial', sans-serif;
}

.back-button {
  background: none;
  border: none;
  color: #ff5a36;
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.details-title {
  font-size: 2rem;
  color: #333;
  margin: 10px 0;
  font-weight: bold;
}

.details-card {
  background: white;
  border: 1px solid #FEEBE6;
  border-radius: 10px;
  padding: 30px;
}

.details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.creator-name {
  font-size: 1.8rem;
  color: #ff5a36;
  margin: 0;
}

.edit-button,
.save-button,
.cancel-button {
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.edit-button {
  background-color: #ff5a36;
  color: white;
  border: none;
}

.edit-button:hover {
  background-color: #DC2A04;
}

.edit-actions {
  display: flex;
  gap: 10px;
}

.cancel-button {
  background-color: transparent;
  color: #ff5a36;
  border: 1px solid #ff5a36;
}

.cancel-button:hover {
  background-color: #ff5a36;
  color: white;
}

.save-button {
  background-color: #ff5a36;
  color: white;
  border: none;
}

.save-button:hover {
  background-color: #DC2A04;
}

.details-section {
  margin-bottom: 30px;
}

.details-section h3 {
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.details-section p {
  color: #666;
  margin: 8px 0;
  font-size: 1rem;
}

.edit-field {
  margin-bottom: 15px;
}

.edit-field label {
  display: block;
  color: #666;
  margin-bottom: 5px;
  font-size: 1rem;
}

.edit-field select,
.edit-field textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
}

.edit-field select {
  background-color: white;
}

.edit-field textarea {
  resize: vertical;
  min-height: 100px;
}

.note-model {
  background-color: #FEEBE6;
  padding: 15px;
  border-radius: 8px;
  font-style: italic;
  line-height: 1.5;
  color: #333;
  width: 100%;
  border: none;
  font-family: inherit;
}

.note-model:focus {
  outline: none;
  box-shadow: 0 0 0 2px #ff5a36;
}

/* Personality fields styling */
.personality-fields {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.personality-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
}

.personality-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #ff5a36;
}

.preview-section {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px dashed #ddd;
}

.note-model-preview {
  background-color: #FEEBE6;
  padding: 15px;
  border-radius: 8px;
  font-style: italic;
  line-height: 1.5;
  color: #333;
  margin-top: 10px;
}

.fan-list-edit {
  margin-top: 15px;
}

.fan-list-input {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.fan-list-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
}

.add-fan-button {
  background-color: #ff5a36;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-fan-button:hover {
  background-color: #DC2A04;
}

.fan-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.fan-item {
  background-color: #FEEBE6;
  padding: 8px 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
  color: #333;
}

.remove-fan-button {
  background: none;
  border: none;
  color: #ff5a36;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.remove-fan-button:hover {
  background-color: #ff5a36;
  color: white;
}

.no-fans-message {
  color: #666;
  font-style: italic;
  margin: 10px 0;
  font-size: 1rem;
}